import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// MODULE
import TranslateModule from '@/store/modules/TranslateModule'
import AuthModule from '@/store/modules/AuthModule'
import DashboardModule from '@/store/modules/DashboardModule'
import UserModule from '@/store/modules/UserModule'
import ResellerModule from '@/store/modules/ResellerModule'
import CategoryModule from '@/store/modules/CategoryModule'
import CategoryScenarioModule from '@/store/modules/CategoryScenarioModule'
import ScenarioModule from '@/store/modules/ScenarioModule'
import MandrelModule from '@/store/modules/MandrelModule'
import OrientationModule from '@/store/modules/OrientationModule'
import PaperModule from '@/store/modules/PaperModule'
import DimensionModule from '@/store/modules/DimensionModule'
import FinishingModule from '@/store/modules/FinishingModule'
import ProvideNumberPerRollsModule from '@/store/modules/ProvideNumberPerRollsModule'
import OrderModule from '@/store/modules/OrderModule'
import OrderRequestCancelModule from '@/store/modules/OrderRequestCancelModule'
import OrderRequestNotConformityModule from '@/store/modules/OrderRequestNotConformityModule'
import QuoteModule from '@/store/modules/QuoteModule'
import CmsCalculatorModule from '@/store/modules/CmsCalculatorModule'
import ConfigEnvModule from '@/store/modules/ConfigEnvModule'
import CountryModule from '@/store/modules/CountryModule'
import ShapeModule from "@/store/modules/ShapeModule";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    TranslateModule,
    AuthModule,
    DashboardModule,
    UserModule,
    ResellerModule,
    CategoryModule,
    CategoryScenarioModule,
    ScenarioModule,
    MandrelModule,
    OrientationModule,
    PaperModule,
    DimensionModule,
    FinishingModule,
    ProvideNumberPerRollsModule,
    OrderModule,
    OrderRequestCancelModule,
    OrderRequestNotConformityModule,
    QuoteModule,
    CmsCalculatorModule,
    ConfigEnvModule,
    CountryModule,
    ShapeModule
  }
})
