<template>
  <div id="page-order">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    {{ /* Heading page */ }}
    <div class="header-pages-block d-md-flex justify-content-between align-items-center">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="box" font-scale="1.5" variant="primary"></b-icon>
        {{ $t('pages.order.title') }}
      </h3>
      <div class="d-flex mb-2 mb-lg-2 mb-xl-0">
        <div>
          <button class="btn btn-sm btn-dark" @click.prevent="exportType = 'csv'; showExportModal = true">Export (CSV)</button>
        </div>
        <div>
          <button class="btn btn-sm btn-dark ml-3" @click.prevent="exportType = 'xlsx'; showExportModal = true">Export (XLSX)</button>
        </div>
      </div>
    </div>

    <div class="div-modal">
      <b-modal
          :key="100"
          id="orders-export-modal"
          ref="modal"
          centered
          :title="$t('exportModal.title')"
          v-model="showExportModal"
          @hidden="showExportModal = false"
          header-bg-variant="primary"
          header-text-variant="light"
          body-bg-variant="light"
          footer-bg-variant="light"
      >
        <hr />
        <div class="d-flex mb-4">
          <button class="btn btn-success mr-2" v-on:click.prevent="checkAllProperties()">
            {{ $t('pages.dashboard.graph.formPeriod.select.button.check') }}
          </button>
          <button class="btn btn-danger" v-on:click.prevent="uncheckAllProperties()">
            {{ $t('pages.dashboard.graph.formPeriod.select.button.unCheck') }}
          </button>
        </div>
        <div class="" v-for="(exportProperty, index) in propertiesToExport" :key="index">
          <label :for="'exportProperty-' + index" class="ml-2">{{ $t(exportProperty.propertyLabel)}}</label>
          <input
              class="ml-3 form-check-input"
              type="checkbox"
              :id="'exportProperty-' + index"
              v-model="exportProperty.isSelected"
          />
        </div>

        <template #modal-footer="{ ok, cancel }">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button size="sm" variant="secondary" @click="cancel()">
            {{ $t('common.cancel') }}
          </b-button>
          <b-button size="sm" variant="success" @click="exportOrders()">
            Export ({{ exportType }})
          </b-button>
        </template>
      </b-modal>
    </div>

    <hr />

    <div class="d-xl-flex align-items-center mb-3 mt-3">
      <div class="d-xl-flex w-100  flex-wrap">
        <div class="search d-flex mr-lg-3 mb-2 mt-lg-0 mt-xl-0 ">
          <div class="input-group">
            <input
                type="text"
                class="form-control border"
                v-model="filter.search"
                :placeholder="$t('common.search.keyword')"
                v-on:keyup.enter="sendResearchRequest"
            >
            <b-icon class="icon-x" icon="x" font-scale="1.5" v-on:click="sendResearchRequestReset"></b-icon>
            <div class="input-group-append d-block">
              <button class="btn btn-success" v-on:click="sendResearchRequest">
                {{ $t('common.search.research') }}
              </button>
            </div>
          </div>
        </div>
        <div class="d-xl-flex ml-0 ml-lg-0 mt-2 mt-lg-2 mt-xl-0">
          {{ /* FILTER STATUS */ }}
          <b-dropdown
              class="dropdown-form-dashboard position-relative d-block  mr-lg-3 mb-2 mt-lg-0 mt-xl-0"
              :text="$t('common.choose') + $t('common.status')"
              ref="dropdown"
              variant="outline-secondary"
          >
            <b-dropdown-form>
              <b-form-checkbox
                  class="mb-3"
                  v-for="(status, index) in OrderModule.orderStatus"
                  :key="index"
                  :value="status.id"
                  v-model="listFilterStatus"
              >
                {{ status.name }}
              </b-form-checkbox>
            </b-dropdown-form>
          </b-dropdown>

          {{ /* FILTER SCENARIO */ }}
          <AutoComplete
              class="mr-lg-3 mb-2 mt-lg-0 mt-xl-0"
              :suggestions="listScenario"
              :research-label="$tc('common.scenario', 1)"
              :placeholder="$t('common.find') + $tc('common.scenario', 0)"
              :is-listing-page="true"
              entity="scenario"
              @click-suggestion="clickSuggestionAutocomplete"
              ref="scenarioAutocomplete"
          />

          {{/* FILTER RESELLER */}}
          <AutoComplete
              class=" mr-lg-3 mb-2 mt-lg-0 mt-xl-0"
              :suggestions="listResellerCompany"
              :research-label="$tc('common.reseller', 1)"
              :placeholder="$t('common.find') + $tc('common.reseller', 0)"
              :is-listing-page="true"
              entity="reseller"
              @click-suggestion="clickSuggestionAutocomplete"
              ref="resellerAutocomplete"
          />
          <div class="d-inline-flex align-items-center">
            <div class="mr-3 mt-lg-0 mt-xl-0 ">
              <b-form-datepicker @input="resetPagination(); fetchOrders()" :placeholder="$t('common.startDate')" id="start-date-datepicker" label-help="" label-no-date-selected="Aucune date sélectionnée" v-model="filter.startDate" class="mb-2"></b-form-datepicker>
            </div>
            <div class="mr-3 mt-lg-0 mt-xl-0 ">
              <b-form-datepicker @input="resetPagination(); fetchOrders()" :placeholder="$t('common.endDate')" id="end-date-datepicker" label-help="" label-no-date-selected="Aucune date sélectionnée" v-model="filter.endDate" class="mb-2"></b-form-datepicker>
            </div>
          </div>
          <div class="d-block">
            <div class=" mr-lg-3 mb-2 mt-lg-0 mt-xl-0 ">
              <b-button
                  class="active"
                  variant="outline-primary"
                  v-on:click="resetFilters"
              >
                {{ $t('common.reset') }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    {{ /* Badge filter reseller */ }}
    <div class="d-xl-flex mb-4" v-if="listBadgeFilter.length > 0">
      <div v-for="(searchObj, index) in listBadgeFilter" :key="index">
        <b-badge class="p-2 ml-2 c-pointer" variant="dark" v-on:click="removeFilter(searchObj)">
          {{ searchObj.result.searchable }}
          <b-icon
              class="ml-2"
              icon="x"
              font-scale="1.5"
              variant="light"
          >
          </b-icon>
        </b-badge>
      </div>
    </div>

    {{ /* Table component */ }}
    <div class="card table-card border-0 shadow" ref="tableOrderComponent">
      <div class="card-body p-0">
        <TableComponent
            :key="99"
            v-if="OrderModule.orders.length !== 0"
            :headerList="configTableComponent.headerList"
            :datas="OrderModule.orders"
            :entityName="'order'"
            :actionList="configTableComponent.actionList"
            :isAction="true"
            @ordered="clickOrdered"
            @specific-action="tableActionEmit"
            @specific-unique-action="tableActionEmit"
            @emit-checkbox-list="emitCheckboxList"
            @emit-checkbox-list-all="emitCheckboxListAll"
        />

        <div v-if="OrderModule.orders.length === 0">
          <div v-if="filter.search != null" class="text-center mt-5">
            {{ $t('common.search.noResult') }}
          </div>
          <div v-else class="text-center mt-5">
            {{ $t('common.none') }} {{ $tc('common.order', 0) }}
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <div class="filter-elem-per-page">
              <select class="form-control" v-model="filter.elemPerPage" v-on:change="changeLimitList">
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>

            <div class="count-elem my-auto ml-3">
              <b>{{ (filter.search) ? OrderModule.totalRequest :  OrderModule.total}}</b>
              {{ $t('common.result') }}
            </div>
          </div>

          <div class="d-flex">
            <vPaginate
                v-if="OrderModule.orders.length > 0"
                class="my-auto"
                :classes="bootstrapPaginationClasses"
                v-model="filter.page"
                :page-count="(OrderModule.totalRequest > 0)
                  ? Math.ceil(OrderModule.totalRequest / filter.elemPerPage)
                  : Math.ceil(OrderModule.total / filter.elemPerPage)
                "
                @change="sendPaginateRequest"
            >
            </vPaginate>
          </div>
        </div>
      </div>
    </div>

    {{/* Modal Cancel Order */}}
    <div class="div-modal">
      <b-modal
          :key="100"
          id="modal-cancel-order"
          ref="modal"
          centered
          :title="$t('pages.order.modalCancelOrder.title')"
          v-model="cancelOrder.clicked"
          @hidden="resetModal"
          @ok="handleModalCancelOrder"
          header-bg-variant="danger"
          header-text-variant="light"
          body-bg-variant="light"
          footer-bg-variant="light"
      >
        <div class="order-data mb-2">
          <h4>{{ $t('pages.order.modalCancelOrder.info') }} {{ $tc('common.order', 0) }} : </h4>
          <b>{{ $tc('common.order', 0) }} n° :</b> {{ (cancelOrder.orderSelected) ? cancelOrder.orderSelected.id : null }}
          <br />
          <b>Total :</b> {{ (cancelOrder.orderSelected) ? cancelOrder.orderSelected.total : null }} €
          <br />
          <b>{{ $t('common.reference') }} :</b> {{ (cancelOrder.orderSelected) ? cancelOrder.orderSelected.quote.reference : null }}
        </div>

        <hr />

        <form ref="form">
          <div class="form-group">
            <label for="cancel-order-comment">{{ $t('common.yourComment') }}</label>
            <textarea
                class="form-control"
                id="cancel-order-comment"
                v-model="cancelOrder.form.comment"
                :placeholder="$t('common.yourComment')">
            </textarea>
          </div>
        </form>

        <template #modal-footer="{ ok, cancel }">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button size="sm" variant="secondary" @click="cancel()">
            {{ $t('common.cancel') }}
          </b-button>
          <b-button size="sm" variant="success" @click="ok()">
            {{ $t('pages.order.modalCancelOrder.send') }}
          </b-button>
        </template>
      </b-modal>
    </div>

    <div class="block-action p-2" style="width: 87%" v-if="listSelectedOrder.length > 0">
      <div class="">
        <h4>{{ $t('pages.order.actions.selected') }} :</h4>
        <div class="">{{ $t('pages.order.actions.numberSelected') }} : <b>{{ listSelectedOrder.length }}</b> </div>
      </div>
      <hr />

      <div class="d-flex">
        {{/* Block action */}}
        <div class="select-action-div">
          <label for="select-action">{{ $t('pages.order.actions.actionSelected') }}: </label>
          <select id="select-action" class="form-control" v-model="selectAction" @input="changeAction">
            <option v-for="(action, index) in listAction" :key="index" :value="action.slug">
              {{ action.name }}
            </option>
          </select>
        </div>

        {{/* Change status */}}
        <div class="mb-3 ml-5" v-if="selectAction === 'change-status'">
          <label for="select-action">{{ $t('common.modifyThe') }} {{ $t('common.status') }}: </label>
          <div class="">
            <select id="select-status" class="form-control" v-model="selectStatus" @input="updateListStatus">
              <option v-for="(status, index) in OrderModule.orderStatus" :key="index" :value="status.id">
                {{ status.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import OrderService from '@/services/OrderService'
import {ordersExportProperties} from '@/services/GlobalService'

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      filter: {
        page: parseInt(this.$route.query.page) || 1,
        elemPerPage: 25,
        order: {
          index: 'createdAt',
          order: 'DESC'
        },
        startDate: null,
        endDate: null,
        search: null,
      },
      showExportModal: false,
      exportType: null,
      propertiesToExport: ordersExportProperties,
      listFilterStatus: [],
      listFilter: [],
      listBadgeFilter: [],
      listResellerCompany: [],
      listScenario: [],
      listSelectedOrder: [],
      mergeFilter: [],
      listAction: [
        { name: 'Modifier le statut', slug: 'change-status' }
      ],
      selectAction: null,
      selectStatus: null,
      cancelOrder: {
        orderSelected: null,
        clicked: false,
        form: {
          comment: null
        }
      },
      bootstrapPaginationClasses: {
        ul: 'pagination',
        li: 'page-item',
        liActive: 'active',
        liDisable: 'disabled',
        button: 'page-link'
      },
      configTableComponent: {
        headerList : [
          {'index' : 'id', 'name' : '#', 'align' : 'left', 'filter': true },
          {'index' : 'internalReference', 'name' : 'ref', 'align' : 'left', 'filter': true},
          {'index' : 'user', 'indexTrad' : 'common.company', 'align' : 'center', 'filter': true, 'relation': true, 'isOnlyAdmin': true },
          {'index' : 'quote', 'indexTrad' : 'common.singleScenario', 'align' : 'center', 'filter': true, 'relation': true, 'specific-relation': 'quote-scenario' },
          {'index' : 'total', 'name' : 'Total', 'align' : 'center', 'filter': true, 'money': true },
          {'index' : 'quote', 'indexTrad' : 'common.ref', 'align' : 'center', 'filter': true, 'relation': true, 'specific-relation': 'quote-reference'},
          {'index' : 'quote', 'indexTrad' : 'common.format', 'align' : 'center', 'filter': true, 'relation': true, 'specific-relation': 'quote-width' },
          {'index' : 'tracking', 'indexTrad' : 'common.carrier', 'align' : 'left', 'filter': true, 'relation' : true},
          {'index' : 'status', 'indexTrad' : 'common.status', 'align' : 'center', 'filter': false, 'relation' : true},
          {'index' : 'createdAt', 'indexTrad' : 'common.created', 'align' : 'center', 'filter': true, 'date' : true, 'datetime': true, 'order': 'DESC'},
          {'index' : 'estimatedAt', 'indexTrad' : 'pages.order.estimatedExp', 'align' : 'center', 'filter': false},
          {'index' : 'cancel-order', 'indexTrad' : 'pages.orderCancel.ask', 'align' : 'center', 'filter': false, 'isOnlyReseller': true},
        ],
        actionList: [
          {
            'indexTrad': 'common.actions.see',
            'routeName' : 'order-show',
            'icon' : 'eye',
            'icon-variant': 'success',
            'params' : true,
            'link-action': true
          },
          {
            'index': 'refresh',
            'indexTrad' : 'common.actions.fileSync',
            'icon' : 'arrow-clockwise',
            'icon-variant': 'primary',
            'isAdminOnly': true,
            'isSpecificAction' : true
          },
          {
            'index': 'download',
            'indexTrad' : 'common.actions.download',
            'icon': 'download',
            'icon-variant': 'warning',
            'isAdminOnly': true,
            'isSpecificUniqueAction': true
          }
        ],
      }
    }
  },
  created() {
    this.showLoader()
    // Order
    this.$store.dispatch('fetchOrders', {
      perPage: this.filter.elemPerPage,
      page: this.filter.page,
      order: this.filter.order,
      search: (this.filter.search) ? this.filter.search : null,
      filters: (this.mergeFilter.length > 0) ? this.mergeFilter : null,
      resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null,
      startDate: this.filter.startDate,
      endDate: this.filter.endDate
    })
    // Order status
    this.$store.dispatch('fetchOrderStatus')
      .then(() => {
        this.hideLoader()
      })
      .catch(() => {
        this.hideLoader()
      })
    // Reseller
    this.$store.dispatch('fetchResellers', {
      perPage: null,
      page: null,
    })

    this.formatResellersList()
    this.formatListScenario()
  },
  watch: {
    listFilterStatus(newValue) {
      this.changeFilterStatus(newValue)
    },
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- SCROLL TABLE ------------------*/
    scrollTable(direction) {
      var getTable = document.querySelector('.table-card')
      if(direction === 'left') {
        getTable.scrollLeft -= 100
      } else {
        getTable.scrollLeft += 100
      }
    },
    /*----------------- FORMAT LIST RESELLERS ------------------*/
    formatResellersList() {
      this.$store.getters.getResellers.forEach((reseller) => {
        this.listResellerCompany.push({
          id: reseller.id,
          searchable: reseller.company
        })
      })
    },
    formatListScenario() {
      this.$store.getters.getScenarios.forEach((scenario) => {
        this.listScenario.push({
          id: scenario.id,
          searchable: scenario.name
        })
      })
    },
    checkAllProperties() {
      this.propertiesToExport.map((propertyToExport) => {
        propertyToExport.isSelected = true;
        return propertyToExport
      })
    },
    uncheckAllProperties() {
      this.propertiesToExport.map((propertyToExport) => {
        propertyToExport.isSelected = false;
        return propertyToExport
      })
    },
    exportOrders() {
      this.showLoader()
      this.propertiesToExport = this.propertiesToExport.map((property) => {
        return {
          ...property,
          translatedLabel: this.$t(property.propertyLabel)
        }
      })

      OrderService.exportOrder({
        type: this.exportType,
        order: this.filter.order,
        search: (this.filter.search) ? this.filter.search : null,
        filters: (this.mergeFilter.length > 0) ? this.mergeFilter : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate,
        propertiesToExport: this.propertiesToExport
      })
      .finally(() => {
        this.hideLoader()
      })
    },
    /*----------------- REFRESH FILES ORDER ------------------*/
    tableActionEmit(obj) {
      // Download Zip
      if(obj.action === 'download') {
        this.showLoader()

        OrderService.refreshFilesOrder(obj.id)
          .then((response) => {
            if(response) {
              this.hideLoader()
            }
          })
      } else if (obj.action === 'cancelOrder') {
        // Open modal
        this.cancelOrder.orderSelected = this.$store.getters.getOrderById(obj.id)
        this.cancelOrder.clicked = true
      } else if (obj.action === 'refresh') {
        this.showLoader()

        let findOrder = this.$store.getters.getOrderById(obj.id)
        if (findOrder.status.ext_id === 5) {
          this.hideLoader()

          this.$bvToast.toast(this.$t('pages.order.toast.orderNotCompliant'),
              {
                title: this.$t('pages.order.toast.fileSyncStatus'),
                variant: 'info',
                solid: true
              }
          )
        } else {
          OrderService.reSyncOrderFiles(obj.id)
              .then((response) => {
                this.hideLoader()

                this.$bvToast.toast(
                    (response.data.message === 'success')
                        ? this.$t('pages.order.toast.askSyncSuccess')
                        : this.$t('pages.order.toast.askSyncAbort'),
                    {
                      title: this.$t('pages.order.toast.fileSyncStatus'),
                      variant: (response.data.message === 'success') ? 'success' : 'info',
                      solid: true
                    }
                )
              })
              .catch(() => {
                this.hideLoader()
              })
        }
      }
    },
    /*----------------- CHECKBOX LIST ------------------*/
    emitCheckboxList(model) {
      if (model.checked && !this.listSelectedOrder.includes(model.id)) {
        this.listSelectedOrder.push(model.id)
      } else {
        let indexListSelectedOrder = this.listSelectedOrder.findIndex(array => array === model.id)
        if(indexListSelectedOrder > -1) {
          this.listSelectedOrder.splice(indexListSelectedOrder, 1)
        }
        this.listSelectedOrder.splice(model.id)
      }
    },
    emitCheckboxListAll(listData) {
      if (listData) {
        listData.forEach((order) => {
          this.listSelectedOrder.push(order.id)
        })
      } else {
        this.listSelectedOrder = []
      }
    },
    /*----------------- CHANGE STATUS ------------------*/
    changeAction($event) {
      this.selectAction = $event.target.value
    },
    updateListStatus()
    {
      this.$swal({
        title: 'Changement de statut !',
        text: 'Voulez vous vraiment changer le statut ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0acf97',
        cancelButtonColor: '#929292',
        confirmButtonText: 'valider',
        cancelButtonText: 'Annuler'
      }).then((result) => {
        if(result.value) {
          // Loader
          this.showLoader()

          // CALL API
          this.$store.dispatch('updateListStatus', { listId: this.listSelectedOrder, status: this.selectStatus })
              .then(() => {
                this.hideLoader()

                this.$bvToast.toast(this.$t('common.changeStatus'), {
                  title: this.$tc('common.changeStatusSuccess', 1),
                  variant: 'success',
                  solid: true
                })

                // Reset
                this.listSelectedOrder = []
                this.selectAction = null
                // Fetch list orders
                this.$store.dispatch('fetchOrders', {
                  perPage: this.filter.elemPerPage,
                  page: this.filter.page,
                  order: this.filter.order,
                  search: (this.filter.search) ? this.filter.search : null,
                  filters: (this.mergeFilter.length > 0) ? this.mergeFilter : null,
                  resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null,
                  startDate: this.filter.startDate,
                  endDate: this.filter.endDate
                })
                // Unchecked checkbox
                let listCheckbox = document.getElementsByClassName('form-check-input')
                listCheckbox.forEach(el => el.checked = false)

                document.getElementById('checkbox-list-order-all').checked = false
              })
              .catch(() => {
                this.hideLoader()
              })
        }
      })
    },
    /*----------------- SELECT LIMIT LIST ------------------*/
    changeLimitList() {
      this.showLoader()

      this.$store.dispatch('fetchOrders', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null,
        filters: (this.listFilterStatus.length > 0) ? this.listFilterStatus : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /*----------------- SELECT FILTER ------------------*/
    changeFilterStatus(listStatusId) {
      this.showLoader()
      this.resetPagination()
      this.listFilterStatus = listStatusId
      this.mergeFilter = this.listFilterStatus.concat(this.listFilter)

      this.$store.dispatch('fetchOrders', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null,
        filters: (this.mergeFilter.length > 0) ? this.mergeFilter : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate
      })
        .then(() => {
          this.hideLoader()
        })
    },
    clickSuggestionAutocomplete(model) {
      this.showLoader()
      this.resetPagination()
      if (model.entity === 'reseller') {
        let index = this.listFilter.findIndex(str => str === model.result.searchable)

        if(index > -1) {
          this.listFilter.splice(index, 1)
          this.listBadgeFilter.splice(index, 1)
        } else {
          this.listFilter.push(model.entity + '|' + model.result.searchable)
          this.listBadgeFilter.push(model)
        }
      } else if (model.entity === 'scenario') {
        let index = this.listFilter.findIndex(str => str === model.result.searchable)

        if(index > -1) {
          this.listFilter.splice(index, 1)
          this.listBadgeFilter.splice(index, 1)
        } else {
          this.listFilter.push(model.entity + '|' + model.result.searchable)
          this.listBadgeFilter.push(model)
        }
      }

      // Merge filter
      this.mergeFilter = this.listFilter.concat(this.listFilterStatus)

      this.$store.dispatch('fetchOrders', {
        perPage: this.filter.elemPerPage,
        page: 1,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null,
        filters: (this.mergeFilter.length > 0) ? this.mergeFilter : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate
      })
          .then(() => {
            this.hideLoader()
          })
    },
    removeFilter(filterObj) {
      this.showLoader()

      if (filterObj.entity === 'reseller') {
        let index = this.listFilter.findIndex(str => str === filterObj.entity +'|'+ filterObj.result.searchable)

        if(index > -1) {
          this.listFilter.splice(index, 1)
          this.listBadgeFilter.splice(index, 1)
        } else {
          this.listFilter.push(filterObj.searchable)
          this.listBadgeFilter.push(filterObj)
        }
      } else if (filterObj.entity === 'scenario') {
        let index = this.listFilter.findIndex(str => str === filterObj.entity +'|'+ filterObj.result.searchable)

        if(index > -1) {
          this.listFilter.splice(index, 1)
          this.listBadgeFilter.splice(index, 1)
        } else {
          this.listFilter.push(filterObj.searchable)
          this.listBadgeFilter.push(filterObj)
        }
      }

      // Merge Filter
      this.mergeFilter = this.listFilter.concat(this.listFilterStatus)

      this.$store.dispatch('fetchOrders', {
        perPage: this.filter.elemPerPage,
        page: 1,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null,
        filters: (this.mergeFilter.length > 0) ? this.mergeFilter : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /*----------------- SEARCH ------------------*/
    /**
     * Reset research
     */
    sendResearchRequestReset()
    {
      this.showLoader()
      this.resetPagination()
      // Reset
      this.filter.search = null

      this.$store.dispatch('fetchOrders', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null,
        filters: (this.mergeFilter.length > 0) ? this.mergeFilter : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /**
     * Send request for research
     */
    sendResearchRequest()
    {
      this.showLoader()

      this.$store.dispatch('fetchOrders', {
        perPage: this.filter.elemPerPage,
        page: 1,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null,
        filters: (this.mergeFilter.length > 0) ? this.mergeFilter : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /*----------------- PAGINATE ------------------*/
    /**
     * Send request for paginate
     */
    sendPaginateRequest()
    {
      this.showLoader()

      this.$store.dispatch('fetchOrders', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: (this.filter.order) ? this.filter.order : null,
        search: (this.filter.search) ? this.filter.search : null,
        filters: (this.mergeFilter.length > 0) ? this.mergeFilter : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate
      })
          .then(() => {
            this.hideLoader()
          })
    },
    /*----------------- MODAL ------------------*/
    resetModal()
    {
      this.cancelOrder.clicked = false
      this.cancelOrder.form.comment = null
    },
    handleModalCancelOrder(bvModalEvt)
    {
      // Prevent modal from closing
      bvModalEvt.preventDefault()

      this.showLoader()

      OrderService.cancelOrder(this.cancelOrder.orderSelected.id, {
        reference : this.cancelOrder.orderSelected.quote.reference,
        comment : this.cancelOrder.form.comment
      })
        .then(() => {
          this.$bvToast.toast(this.$t('pages.orderCancel.askSuccess'), {
            title: this.$t('pages.orderCancel.ask'),
            variant: 'success',
            solid: true
          })

          this.cancelOrder.clicked = false

          this.$store.dispatch('fetchOrders', {
            perPage: this.filter.elemPerPage,
            page: this.filter.page,
            order: (this.filter.order) ? this.filter.order : null,
            search: (this.filter.search) ? this.filter.search : null,
            filters: (this.listFilterStatus.length > 0) ? this.listFilterStatus : null,
            resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null,
            startDate: this.filter.startDate,
            endDate: this.filter.endDate
          })

          this.hideLoader()
        })
    },
    /*----------------- ORDER ------------------*/
    /**
     * Send request for order
     * @param order
     */
    clickOrdered(order)
    {
      this.showLoader()

      this.filter.order = order

      this.$store.dispatch('fetchOrders', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page,
        order: order,
        search: (this.filter.search) ? this.filter.search : null,
        filters: (this.mergeFilter.length > 0) ? this.mergeFilter : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate
      })
          .then(() => {
            this.hideLoader()
          })
    },
    fetchOrders() {
      this.showLoader()
      this.$store.dispatch('fetchOrders', {
        perPage: this.filter.elemPerPage,
        page: this.filter.page ? this.filter.page : 1,
        order: this.filter.order,
        search: (this.filter.search) ? this.filter.search : null,
        filters: (this.mergeFilter.length > 0) ? this.mergeFilter : null,
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate
      }).then(() => {
        this.hideLoader()
      }).catch(() => {
        this.hideLoader()
      })
    },
    resetFilters() {
      this.mergeFilter = [];
      this.filter.startDate = null;
      this.filter.endDate = null;
      this.filter.search = null;
      this.listFilterStatus = [];
      this.listFilter = [];
      this.listBadgeFilter = [];
      this.listResellerCompany = [];
      this.listScenario = [];
      this.listSelectedOrder = [];
      this.resetPagination();
      this.$refs.scenarioAutocomplete.$data.selection = ''
      this.$refs.resellerAutocomplete.$data.selection = ''
      this.fetchOrders()
    },
    resetPagination() {
      this.filter.page = 1;
      this.filter.elemPerPage = 25;
    }
  },
  computed: {
    ...mapState(['OrderModule']),
    ...mapState(['ScenarioModule']),
    ...mapState(['ResellerModule']),
    ...mapState(['OrderRequestNotConformityModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay'),
    TableComponent: () => import('@/components/_shared/tableComponent'),
    vPaginate: () => import('vue-plain-pagination'),
    AutoComplete: () => import('@/components/_shared/autoComplete')
  }
}
</script>

<style scoped>

</style>
